ptz-accordion {
  --accordion-content-height: 0;

  .accordion {
    &-wrapper {
      display: flex;
      flex-direction: column;
      border-bottom: $petz-border-sm solid $petz-color-neutral-light;
      height: auto;
      width: auto;
      padding-bottom: 0;
    }

    &-header {
      padding: 0 $petz-spacing-md;
      display: flex;
      align-items: center;
      gap: $petz-spacing-xs;
      border: none;
      background-color: transparent;
      flex: 1;
      cursor: pointer;
      transition: all .4s ease;
      position: relative;

      &-md {
        padding-bottom: $petz-spacing-sm;
        padding-top: $petz-spacing-sm;
      }

      &-lg {
        padding-bottom: $petz-spacing-md;
        padding-top: $petz-spacing-md;
      }

      &-label {
        justify-self: flex-start;
      }

      &-arrow {
        position: absolute;
        right: $petz-spacing-md;
        top: 0;
        bottom: 0;
        align-self: flex-end;
        transition: 0.5s ease-out;

        &.rotate {
          transform: rotate(-180deg);
        }
      }
    }

    &-content {
      padding: 0 $petz-spacing-md;
      overflow: hidden;
      transition: all 0.4s ease-out;
      max-height: var(--accordion-content-height);

      &-open {
        &.accordion-content {
          &-md {
            padding-bottom: $petz-spacing-sm;
          }

          &-lg {
            padding-bottom: $petz-spacing-md;
          }
        }
      }
    }
  }

  ptz-icon {
    display: flex;
  }

  ptz-skeleton {
    width: 100%;
    margin-bottom: $petz-spacing-2xs;
  }
}
