import { Component, Element, Event, EventEmitter, Host, Prop, State, h } from '@stencil/core';
import { valueToLowerCaseForDataTestId } from '../../utils/utils';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZAccordionTypes } from './types/ptz-accordion.types';

@Component({
  tag: 'ptz-accordion',
  styleUrl: 'ptz-accordion.scss',
  shadow: false,
})
export class PTZAccordion {
  /** Título exibido no botão do accordion */
  @Prop() label: string = '';
  /** Variante do accordion */
  @Prop() variant?: PTZAccordionTypes.Variant = 'default';
  /** Tamanho do accordion */
  @Prop() size?: PTZAccordionTypes.Size = 'md';
  /** Nome do ícone no Accordion */
  @Prop() iconName?: PTZIconTypes.Name;
  /** Variante do ícone no Accordion */
  @Prop() iconVariant?: PTZIconTypes.Variant = 'line';
  /** Atributo checked padrão do Checkbox  */
  @Prop({ mutable: true }) checkboxChecked?: boolean = false;
  /** Preenche id padrão do Checkbox */
  @Prop() checkboxInputId?: string;
  /** Atributo name padrão do Checkbox */
  @Prop() checkboxName?: string;
  /** Atributo value padrão do Checkbox */
  @Prop() checkboxValue?: string;
  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  @Element() el: HTMLElement;

  /** Estado aberto ou fechado do Accordion */
  @State() open: boolean = false;

  @Event({
    eventName: 'toggleAccordion',
    composed: true,
  })
  toggleAccordion: EventEmitter<boolean>;

  private toggleAccordionHandler() {
    const contentContainerHeight = this.el.querySelector('.accordion-content').scrollHeight;
    this.open = !this.open;
    this.toggleAccordion.emit(this.open);

    if (this.open) {
      this.el.style.setProperty('--accordion-content-height', `${contentContainerHeight}px`);
    } else {
      this.el.style.setProperty('--accordion-content-height', `${0}px`);
    }
  }

  render() {
    const handleToggleAccordion = () => {
      this.toggleAccordionHandler();
    };

    const preventButtonClick = event => {
      event.stopPropagation();
    };

    return (
      <Host>
        <div class="accordion-wrapper">
          {this.skeleton ? (
            <ptz-skeleton height={37} rounded="xs"></ptz-skeleton>
          ) : (
            <button class={{ 'accordion-header': true, [`accordion-header-${this.size}`]: true }} aria-expanded={this.open} onClick={handleToggleAccordion}>
              {this.variant === 'icon' && <ptz-icon name={this.iconName} variant={this.iconVariant} color="neutral-black" />}
              {this.variant === 'checkbox' && (
                <ptz-checkbox
                  class="accordion-checkbox"
                  size="sm"
                  name={this.label}
                  checked={this.checkboxChecked}
                  inputId={this.checkboxInputId}
                  value={this.checkboxValue}
                  onClick={preventButtonClick}
                />
              )}
              <span data-testid={`accordion-${valueToLowerCaseForDataTestId(this.label)}-title`} class="accordion-header-label">
                {this.label}
              </span>
              <ptz-icon
                testId={`accordion-${valueToLowerCaseForDataTestId(this.label)}-arrow`}
                class={{ 'accordion-header-arrow': true, 'rotate': this.open }}
                name="petz-chevron-down"
                variant="line"
                color="neutral-black"
                size="lg"
              />
            </button>
          )}

          <div
            data-testid={`accordion-${valueToLowerCaseForDataTestId(this.label)}-content`}
            class={{ 'accordion-content': true, 'accordion-content-open': this.open, [`accordion-content-${this.size}`]: true }}
            aria-hidden={!this.open}
          >
            <slot />
          </div>
        </div>
      </Host>
    );
  }
}
